import revive_payload_client_NlbO0vBIgR from "/app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_oE9CsgdRNh from "/app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_480P1fs5Ok from "/app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MfU0D3dslB from "/app/node_modules/.pnpm/nuxt-site-config@2.2.18_rollup@4.24.0_vite@5.4.8_vue@3.5.11/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_1QKswjJoCA from "/app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_eTXCarvt5v from "/app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_eSu6PQnjYK from "/app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_YHli2M6eYM from "/app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_qq5WjHbMkr from "/app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_w4O4Y9DIol from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_rollup@4.24.0_vite@5.4.8_vue@3.5.11/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_g4vDLjoW8H from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_rollup@4.24.0_vite@5.4.8_vue@3.5.11/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_kp2JnIlUbh from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_rollup@4.24.0_vite@5.4.8_vue@3.5.11/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_g4TeGJMdC1 from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_rollup@4.24.0_vite@5.4.8_vue@3.5.11/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_3tlBnetDd0 from "/app/node_modules/.pnpm/nuxt-csurf@1.6.3_rollup@4.24.0/node_modules/nuxt-csurf/dist/runtime/plugin.js";
import switch_locale_path_ssr_uQxXoWhtBq from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.11/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_0T4Bi5RaJ6 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.11/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_NWNy1q9oud from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.8.2_next-auth@4.21.1_rollup@4.24.0/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import loadexternals_xjRdSvYvRI from "/app/plugins/loadexternals.ts";
import trpcClient_M8UiGTyzsx from "/app/plugins/trpcClient.ts";
import vue_data_ui_client_0F4FrKqNt4 from "/app/plugins/vue-data-ui.client.ts";
export default [
  revive_payload_client_NlbO0vBIgR,
  unhead_oE9CsgdRNh,
  router_480P1fs5Ok,
  _0_siteConfig_MfU0D3dslB,
  payload_client_1QKswjJoCA,
  navigation_repaint_client_eTXCarvt5v,
  check_outdated_build_client_eSu6PQnjYK,
  chunk_reload_client_YHli2M6eYM,
  components_plugin_KR1HBZs4kY,
  prefetch_client_qq5WjHbMkr,
  titles_w4O4Y9DIol,
  defaultsWaitI18n_g4vDLjoW8H,
  siteConfig_kp2JnIlUbh,
  inferSeoMetaPlugin_g4TeGJMdC1,
  plugin_3tlBnetDd0,
  switch_locale_path_ssr_uQxXoWhtBq,
  i18n_0T4Bi5RaJ6,
  plugin_NWNy1q9oud,
  loadexternals_xjRdSvYvRI,
  trpcClient_M8UiGTyzsx,
  vue_data_ui_client_0F4FrKqNt4
]