import { default as errorT0PrdEAY6vMeta } from "/app/pages/auth/error.vue?macro=true";
import { default as signinFK2lTWFqnvMeta } from "/app/pages/auth/signin.vue?macro=true";
import { default as verify_45requestevDC4PTXAqMeta } from "/app/pages/auth/verify-request.vue?macro=true";
import { default as indextzb25vHoj6Meta } from "/app/pages/dashboard/account/index.vue?macro=true";
import { default as _91slug_93rcdjhHtk5RMeta } from "/app/pages/dashboard/applications/[slug].vue?macro=true";
import { default as settings9rSduxhdWKMeta } from "/app/pages/dashboard/applications/settings.vue?macro=true";
import { default as billingmDF0xtQMYaMeta } from "/app/pages/dashboard/billing.vue?macro=true";
import { default as get_45startedMc8HzZCUX5Meta } from "/app/pages/dashboard/get-started.vue?macro=true";
import { default as indexhg2nWJrbRRMeta } from "/app/pages/dashboard/index.vue?macro=true";
import { default as index8HWYX8hAW5Meta } from "/app/pages/dashboard/integrations/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as privacyg3gwBRGXatMeta } from "/app/pages/privacy.vue?macro=true";
import { default as termsy18ZESLqu6Meta } from "/app/pages/terms.vue?macro=true";
import { default as component_45stubVE894n8BYqMeta } from "/app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubVE894n8BYq } from "/app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "auth-error___en",
    path: "/auth/error",
    meta: errorT0PrdEAY6vMeta || {},
    component: () => import("/app/pages/auth/error.vue")
  },
  {
    name: "auth-signin___en",
    path: "/auth/signin",
    meta: signinFK2lTWFqnvMeta || {},
    component: () => import("/app/pages/auth/signin.vue")
  },
  {
    name: "auth-verify-request___en",
    path: "/auth/verify-request",
    meta: verify_45requestevDC4PTXAqMeta || {},
    component: () => import("/app/pages/auth/verify-request.vue")
  },
  {
    name: "dashboard-account___en",
    path: "/dashboard/account",
    meta: indextzb25vHoj6Meta || {},
    component: () => import("/app/pages/dashboard/account/index.vue")
  },
  {
    name: "dashboard-applications-slug___en",
    path: "/dashboard/applications/:slug()",
    meta: _91slug_93rcdjhHtk5RMeta || {},
    component: () => import("/app/pages/dashboard/applications/[slug].vue")
  },
  {
    name: "dashboard-applications-settings___en",
    path: "/dashboard/applications/settings",
    meta: settings9rSduxhdWKMeta || {},
    component: () => import("/app/pages/dashboard/applications/settings.vue")
  },
  {
    name: "dashboard-billing___en",
    path: "/dashboard/billing",
    meta: billingmDF0xtQMYaMeta || {},
    component: () => import("/app/pages/dashboard/billing.vue")
  },
  {
    name: "dashboard-get-started___en",
    path: "/dashboard/get-started",
    meta: get_45startedMc8HzZCUX5Meta || {},
    component: () => import("/app/pages/dashboard/get-started.vue")
  },
  {
    name: "dashboard___en",
    path: "/dashboard",
    meta: indexhg2nWJrbRRMeta || {},
    component: () => import("/app/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-integrations___en",
    path: "/dashboard/integrations",
    meta: index8HWYX8hAW5Meta || {},
    component: () => import("/app/pages/dashboard/integrations/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "privacy___en",
    path: "/privacy",
    meta: privacyg3gwBRGXatMeta || {},
    component: () => import("/app/pages/privacy.vue")
  },
  {
    name: "terms___en",
    path: "/terms",
    meta: termsy18ZESLqu6Meta || {},
    component: () => import("/app/pages/terms.vue")
  },
  {
    name: component_45stubVE894n8BYqMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubVE894n8BYq
  },
  {
    name: component_45stubVE894n8BYqMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubVE894n8BYq
  },
  {
    name: component_45stubVE894n8BYqMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubVE894n8BYq
  }
]