export default defineNuxtRouteMiddleware((_to, _from) => {
  const { data } = useAuth()
  const { user } = data.value || {}

  if (_to.meta.uncheckOnboarding) {
    return
  }

  if (!user) {
    return navigateTo('/auth/signin')
  }

  if (user.onBoardingDone) {
    return _to.meta.redirectIfOnboarded ? navigateTo('/dashboard') : void 0
  }

  return navigateTo('/dashboard/get-started')
})
