<script lang="ts" setup>
import { ConfigProvider } from 'radix-vue'
import { UseColorMode } from '@vueuse/components'

function useIdFunction() {
  let id = useId()
  while (id === undefined) {
    id = useId()
  }
  return id
}
useColorMode()
</script>

<template>
  <ConfigProvider :use-id="useIdFunction">
    <UseColorMode>
      <NuxtPage />
    </UseColorMode>
  </ConfigProvider>
</template>
