import validate from "/app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/validate.js";
import billing_45global from "/app/middleware/billing.global.ts";
import onboarding_45global from "/app/middleware/onboarding.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  billing_45global,
  onboarding_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.8.2_next-auth@4.21.1_rollup@4.24.0/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}